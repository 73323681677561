.App {
  text-align: center;
}

.loginPage {
  position: absolute;
  left: 0;
  top: 0;

  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.loginPage {
  text-align: center;
}

/* --- Copied from Chromium source code, BSD licensd --- */

/*
https://github.com/ChromeDevTools/devtools-frontend/blob/master/front_end/elements/elementsTreeOutline.css
*/

/* DOM update highlight */
@keyframes dom-update-highlight-animation {
  from {
    background-color: rgb(158 54 153);
    color: white;
  }

  80% {
    background-color: rgb(245 219 244);
    color: inherit;
  }

  to {
    background-color: inherit;
  }
}

@keyframes dom-update-highlight-animation-dark {
  from {
    background-color: rgb(158 54 153);
    color: white;
  }

  80% {
    background-color: #333;
    color: inherit;
  }

  to {
    background-color: inherit;
  }
}

:not(.shouldBlur) .dom-update-highlight {
  animation: dom-update-highlight-animation 1.4s 1 cubic-bezier(0, 0, 0.2, 1);
  border-radius: 2px;
}

/* --- End Chromium copy --- */

@keyframes change-highlight-gt-animation {
  from {
    background-color: rgb(5, 136, 0);
    color: white;
  }

  80% {
    background-color: rgb(245 219 244);
    color: inherit;
  }

  to {
    background-color: inherit;
  }
}

:not(.shouldBlur) .change-highlight-gt {
  animation: change-highlight-gt-animation 1.4s 1 cubic-bezier(0, 0, 0.2, 1);
  border-radius: 2px;
}

@keyframes change-highlight-lt-animation {
  from {
    background-color: rgb(158, 54, 54);
    color: white;
  }

  80% {
    background-color: rgb(245 219 244);
    color: inherit;
  }

  to {
    background-color: inherit;
  }
}

:not(.shouldBlur) .change-highlight-lt {
  animation: change-highlight-lt-animation 1.4s 1 cubic-bezier(0, 0, 0.2, 1);
  border-radius: 2px;
}
